
            @import 'src/styles/mixins.scss';
        
.bg-style {
    position: relative;
    margin-top: -95px;
    @include lg {
        margin-top: -86px;
    }
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        top: 0;
        position: absolute;
        background-color: #f7f7f7;
        z-index: -2;
    }
}
