
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.item {
    background-color: #f3f3f3;
    border-radius: 16px;
    max-width: 350px;
    padding: 50px 40px 40px 40px;
    min-height: 335px;
}
.bg-style {
    position: relative;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        top: 0;
        position: absolute;
        background-color: rgba(217, 217, 217, 0.2);
        z-index: -2;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("/assets/images/benefitsImg.jpg");
    }
}
