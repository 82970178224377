
            @import 'src/styles/mixins.scss';
        
.mainModal {
    background: rgba(22, 22, 22, 0.9);
    backdrop-filter: blur(7.25px);
}
.main-banner {
    position: relative;

    .form {
        z-index: 5;
        width: 100%;
        max-width: 750px;
        margin: 20px auto 100px auto;
        @include lg {
            margin: 47px auto 217px auto;
        }

        .step-title {
            color: #fff;
            font-size: 24px;
            font-weight: bold !important;
            margin: 0;
            @include lg {
                font-size: 30px !important;
                line-height: 1.3;
            }
            > span {
                > span {
                    color: #fb6c05;
                }
            }
        }

        .step-description {
            margin-top: 10px !important;
            color: #fff;
            text-align: center;
            font-weight: 300;
            margin-bottom: 30px !important;
            font-size: 16px;
            @include lg {
                margin-top: 29px !important;
                margin-bottom: 60px !important;

                font-size: 18px;
            }
        }
        .fields-wrapper {
            max-width: 550px;
            margin: 0 auto !important;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            > div {
                width: 100%;
            }
            > div:nth-child(2),
            > div:nth-child(3) {
                @include lg {
                    width: 48%;
                }
            }
        }
        .form-button {
            border-radius: 70px !important;
            background: #fb6c05 !important;
            transition: all 0.2s ease-in-out;
            font-weight: 700;
            max-width: 200px;
            margin: 10px auto 0 auto;
            @include lg {
                font-size: 16px;
            }
        }

        .form-field {
            margin-bottom: 10px;
            > label {
                font-weight: 500;
                color: #fff;
                text-align: left !important;
                margin-left: 10px;
            }
        }

        .input-field {
            border: 1px solid #1c1c1c;
            border-radius: 16px;
        }

        .tcpa {
            text-align: center;
            margin-top: 30px;
            color: #fff !important;
            @include lg {
                margin-top: 60px;
            }
        }
        .error-style {
            margin-left: 10px;
            font-weight: 500;
            text-transform: capitalize;
        }
    }
}

.thankYouStyle {
    max-width: 600px;
    margin: 20px auto;
    @include lg {
        margin: 47px auto;
    }

    > div {
        > div {
            > div {
                > span {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
    img {
        filter: brightness(0) saturate(100%) invert(41%) sepia(62%)
            saturate(2213%) hue-rotate(2deg) brightness(104%) contrast(97%);
    }
    h2 {
        margin-top: 10px;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        @include lg {
            font-size: 30px;
        }
    }
    p {
        &:first-of-type {
            margin-top: 40px;
        }
        margin-top: 25px;
        color: #fff;
        font-size: 16px;
        @include lg {
            font-size: 18px;
        }
    }
}
