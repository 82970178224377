
            @import 'src/styles/mixins.scss';
        
.buttonStyle {
    display: flex;
    align-items: center;
    background-color: #fb6c05;
    font-size: 14px;
    font-weight: bold;
    padding: 16px 32px;
    border-radius: 70px;
    color: white;
    @include lg {
        font-size: 16px;
    }
}
.arrow {
    display: block;
    animation-name: flickIcon;
    animation-duration: 1.4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    margin-left: 10px;
}
@keyframes flickIcon {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(7px);
    }
    100% {
        transform: translateX(0px);
    }
}
