
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.bg-style {
    position: relative;
    min-height: calc(100vh + 94px);
    @include lg {
        min-height: calc(100vh + 86px);
    }
    // border: 1px solid green;
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        top: -94px;
        position: absolute;
        background-color: #f7f6f3;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        z-index: -2;
        background-image: url("/assets/images/premiumCoverageHomeMobileBg.svg");
        @include lg {
            background-image: url("/assets/images/premiumCoverageHomeDesktopBg.svg");
            top: -86px;
        }
    }
}
